.product-page {
	.product-list {
		position: relative;
		height: auto;

		& .filter {
			width: calc(25% - 12px);
			@include r(767) {
				width: 100%; } } }

	.product-list__filter {
		a {
			display: none; } } }
