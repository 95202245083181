.aside {
    width: 24.6vw;

    @include r(1023) {
        width: 100%;
        padding-top: 17px;
        margin-bottom: 62px;

        border-top: 1px solid #5a5a5a; }

    &__link {
        font-size: 14px;
        line-height: 1.79;
        letter-spacing: -0.02em;
        color: $grey;
        cursor: pointer; }

    &__nav {
        margin-bottom: 7px; }

    &__button {
        padding-top: 3px;

        font-family: inherit;
        font-size: 12px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: $grey;

        background: transparent; } }
