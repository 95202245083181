.scrollbar {
	position: fixed;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 10;
	padding: 5px 0;

	&__inner {
		height: 100%;
		position: relative; }

	&__thumb {
		min-height: 15px;
		// width: 2px
		width: 0;
		// background-color: $grey
		background-color: transparent;
		position: absolute;
		right: 2px;
		border-radius: 4px; }

	@include r(768) {
		display: none; } }

@supports (-webkit-touch-callout: none) {
	.scrollbar {
		display: none; } }

