.categories {
	&_inner {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 84px 18px;
		@include r(767) {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 36px 15px; } }

	&_view {
		padding: 24px 0 8px;
		font-size: 13px;
		line-height: 0.92;
		letter-spacing: -0.02em;

		color: $grey;
		text-decoration: none;
		&::before {
			content: url(../img/view2.svg);
			margin-right: 12px;
			@include r(767) {
				content: url(../img/view1.svg); } }
		&::after {
			content: '2';
			@include r(767) {
				content: '1'; } } }

	&_filter {
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin-bottom: 25px; } }
