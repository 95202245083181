$white: #fff;
$black: #000;
$grey: #949494;
$darkGrey: #5A5A5A;
$darkestGrey: #313131;
$lightGrey: #E3E3E3;
$lightestGrey: #D1D1D1;
$darkWhite: #B2B2B2;
$background: #ECEBEC;
$blue: #5C92DC;
$orange: #FF8C30;
$green: #AECBB3;
$pink: #FFC8C6;
$red: #CB0000;
$white-color: #F9F6F5;
$madWhite: #D4D5D5;
$violet: #7167EF;
$hoverline: #C6C6C6;
$burgerGrey: #D9D9D9;
