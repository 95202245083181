.filter {
	position: relative;
	width: calc(50% - 8px);
	overflow: hidden;

	@include r(767) {
		width: calc(50% - 8px); }

	&::before, &::after {
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;

		width: 100%;
		height: 1px;

		background-color: $darkGrey; }

	&::before {
		z-index: 10;

		background-color: $hoverline;

		transform: translateX(-100%);
		transition: transform 500ms cubic-bezier(0.66, 0, 0.34, 1); }

	&:hover {
		&::before {
			transform: translateX(0); } }

	&__select {
		width: 100%;
		padding-top: 24px;
		padding-bottom: 14px;

		color: $white;
		font-family: inherit;
		font-size: 13px;
		line-height: 0.92;
		letter-spacing: -0.02em;
		text-transform: uppercase;

		background: transparent;
		border: 0;
		border-radius: 0;

		appearance: none;
		-webkit-appearance: none;

		option {
			background: $black; } }

	&__label {
		pointer-events: none;
		position: absolute;
		right: 0;
		bottom: 14px;

		display: block;
		width: 55px;

		color: $grey;
		font-size: 13px;
		line-height: 0.92;
		letter-spacing: -0.02em;

		background-image: url("../img/filter.svg");
		background-repeat: no-repeat;
		background-position: right; } }
