.terms__block {
    margin-bottom: 36px;
    padding-bottom: 6px;

    border-bottom: 1px solid $darkestGrey;

    h3 {
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.02em; }

    p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0;

        color: $grey;
        text-transform: initial; } }

