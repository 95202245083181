* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@include font("Neue Haas Grotesk Display", "Neue Haas Grotesk Display");
@include font("Arial Narrow", "Arial Narrow");

p {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	letter-spacing: -0.02em;

	color: $white;
	@include r(330) {
		font-size: 12px; }
	&.black {
		color: $black; }
	&.grey {
		color: $grey; } }
h2 {
	font-weight: 500;
	@include css-lock(43, 83, 375, 1920);
	@include css-lock(49, 99, 375, 1920, line-height);
	letter-spacing: -0.06em;

	color: $black;
	&.white {
		color: $white; }
	&.center {
		text-align: center; } }
h4 {
	font-size: 15px;
	line-height: 22px;
	font-weight: 400;

	color: $white;

	margin-bottom: 10px; }
.link {
	$link: &;
	font-weight: 400;
	font-size: 13px;
	line-height: 1.2;
	letter-spacing: -0.02em;
	color: $black;

	height: fit-content;
	display: block;
	width: fit-content;

	position: relative;
	overflow: hidden;

	&__line {
		position: absolute;
		bottom: 0px;
		right: 0;
		left: 0;
		height: 1px;
		background-color: $black;
		&.light {
			background-color: $grey; }
		&.dark {
			background-color: $darkGrey; } }

	&.grey {
		color: $darkWhite;

		#{$link}__line {
			background-color: $darkWhite; } }

	&.share {
		background-color: transparent;
		text-transform: capitalize;
		padding-bottom: 1px; } }
.share__content {
	height: 1px;
	width: 0;
	opacity: 0;
	pointer-events: none;
	user-select: none; }

.undertext {
	font-weight: 400;
	font-size: 11px;
	line-height: 15px;

	letter-spacing: 0.01em;
	color: $black; }
input {
	color: $white;

	text-transform: uppercase; }
.info {
	h4 {
		font-size: 15px;
		line-height: 22px;
		font-weight: 400;

		color: $white;

		margin-bottom: 10px; }
	p {
		margin-bottom: 20px;

		font-weight: 400;
		font-size: 14px;
		line-height: 21px;

		letter-spacing: -0.02em;

		color: $grey;
		&:last-child {
			margin-bottom: 0; } } }
