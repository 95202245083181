.cart {
	position: fixed;
	z-index: 160;
	top: 0;
	right: 0;
	bottom: 0;

	width: 51.3%;

	padding: 20px 15px 100px;
	background: $black;

	overflow-y: scroll;

	transform: translateX(100%);

	&::-webkit-scrollbar {
        width: 0; }

	@include r(767) {
		width: 100%; }

	&__inner {
		margin-top: 72px;
		margin-bottom: 35px;

		user-select: none; }

	&__overline {
		margin: 0;

		border-color: $darkGrey;

		&--white {
			border-color: $white; } }

	&__item {
		display: flex;
		padding: 24px 0; }

	&__img {
		width: 108px;
		margin-right: 30px;

		background: $background;

		@include r(767) {
			margin-right: 25px; } }

	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:first-child {
			margin-bottom: 45px; } }

	&__description {
		flex: 1; }

	&__quantity {
		display: flex;
		p {
			@include useFont('Neue Montreal', sans-serif); } }

	&__controls {
		display: flex;
		align-items: center;
		margin-left: 20px;

		@include useFont('Neue Montreal', sans-serif);

		button {
			outline: none;
			background: transparent;
			border: 0;

			width: 12px;
			margin: 0 2px;

			font-family: inherit;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;

			letter-spacing: -0.02em;
			text-transform: uppercase;
			color: $white-color; }

		input {
			display: inline-block;
			width: 14px;
			text-align: center; } }

	&__remove-btn {
		color: $grey;
		text-transform: uppercase;
		background-color: transparent; }

	&__line {
		background-color: $white-color;
		margin: 0; }

	&__checkout {
		display: flex;
		justify-content: flex-end;

		&.hidden {
			display: none; } }

	&__total {
		margin-left: 11px; }

	&__checkout-btn {
		margin-left: 115px;

		color: $white;
		text-transform: uppercase;
		background-color: transparent; }

	&__close {
		position: absolute;
		top: 31px;
		left: 2.5%;
		z-index: 161;

		display: table;
		width: 16px;
		height: 16px;

		background: transparent;
		cursor: pointer;
		outline: none;
		border: 0;

		@include r(767) {
			margin: 5px auto 0; }

		&:before,
		&:after {
			position: absolute;
			content: "";
			left: 0;

			width: 100%;
			height: 1px;

			background: $grey;
			transition: transform .2s linear; }

		&:before {
			transform: rotate(45deg); }

		&:after {
			transform: rotate(-45deg); } }

	&__empty {
		font-weight: 400;
		font-size: 13px;
		line-height: 12px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #949494;

		align-items: center;
		gap: 5px;

		display: none;

		&.show {
			display: flex; }

		a {
			color: inherit; } } }
