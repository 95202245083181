// general account styles

.account {
    padding: 17px 0 199px;

    @include useFont("Arial Narrow", sans-serif);

    border-top: 1px solid $darkGrey;

    @include rmin(1024) {
        padding: 17px 0 80px; }
    @include r(1023) {
        border-top: none; }

    &__section {
        @include rmin(1024) {
            display: flex; } }

    &__inner {
        color: $white;

        @include rmin(1024) {
            width: 48.5vw; } }

    &__text {
        color: $grey;
        text-transform: initial;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: 0.01em; }

    &__link {
        display: table;
        padding-top: 3px;
        white-space: nowrap;

        color: $grey;
        font-size: 12px;
        line-height: 1.1;
        font-weight: 400;

        @include r(1280) {
            text-align: end; } }

    &__link--white {
        display: block;
        width: 53px;
        margin-left: auto;

        color: $white;
        font-size: 14px;
        line-height: 1.1;
        letter-spacing: -0.02em; }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        margin-bottom: 29px; }

    &__wrapper--bordered {
        padding-bottom: 22px;
        margin-bottom: 36px;

        border-bottom: 1px solid $darkestGrey; }

    &__wrapper--close {
        margin-bottom: 0; }

    &__envelope {
        display: flex;
        flex-direction: column; }

    &__address {
        text-transform: initial;

        p {
            color: $white;
            font-size: 15px;
            line-height: 1.67; } }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 5px; }

    &__button {
        font-family: inherit;
        color: $grey;
        font-size: 12px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        background: transparent; } }

// form styles

.form {
    position: relative;

    padding-bottom: 30px;

    &__wrapper {

        @include rmin(1024) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; } }

    &__wrapper--account {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; }

    &__element {
        margin-bottom: 29px; }

    &__link {
        padding-top: 3px;

        color: $grey;
        font-size: 12px;
        line-height: 1.1;

        @include r(1280) {
            text-align: end; } }

    &__label {
        margin-bottom: 4px;

        color: $grey;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.02em; }

    &__input {
        display: block;
        height: 38px;

        font-family: inherit;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: 0.01em;
        text-transform: initial;

        border-bottom: 1px solid $grey;
        border-radius: 0;
        transition: border-bottom 500ms ease;

        @include rmin(1024) {
            min-width: 252px; }

        @include rmin(1480) {
            min-width: 364px; }

        &:focus {
            border-bottom: 1px solid $grey; }

        &:placeholder-shown {
            border-bottom: 1px solid $darkestGrey; } }

    &__input--email {
        color: $white;
        text-transform: lowercase; }

    &__input--password {
        color: $darkGrey; }

    &__input--name {
        text-transform: capitalize; }

    &__select {
        width: 100%;

        font-family: inherit;
        color: $white;
        background-color: transparent;
        outline: none;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid $darkestGrey;

        appearance: none;
        -webkit-appearance: none; }

    &__option {
        font-family: inherit;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: 0.01em;
        text-transform: initial;

        background-color: $black;
        outline: none;
        -webkit-border: none;
        border: none; }

    &__checkbox {
        display: flex;
        align-items: center;
        gap: 6px;

        color: $grey;
        text-transform: initial;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: 0.01em;

        border-bottom: 1px solid $darkestGrey; }

    &__checkbox--real {
        width: 10px;
        appearance: none; }

    &__checkbox--false {
        position: absolute;

        display: block;
        width: 10px;
        height: 10px;

        border: 1px solid $grey; }

    &__checkbox--real:checked+.form__checkbox--false {
        display: block;

        background-color: $black;
        background: url("../img/square.jpg") no-repeat;
        background-size: cover; }

    &__submitBtn {
        position: absolute;
        bottom: -9px;
        right: 0;

        padding: 10px 0 0;

        color: $white;
        font-family: inherit;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        background: transparent; } }

//table styles

.table {
    color: $grey;
    text-transform: capitalize;
    margin-bottom: 23px;

    &--bordered {
        @include rmin(1024) {
            padding-bottom: 11px;

            border-bottom: 1px solid $darkestGrey; } }
    &__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 5px;

        @include r(1023) {
            padding: 16px 0;
            border-bottom: 1px solid $darkGrey; } }

    &__row--bordered {
        border-bottom: 1px solid $darkestGrey; }

    &__group {
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 68%;
        min-height: 51px;

        @include r(1023) {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px; }

        &--ilustrated {
            padding-left: 67px; } }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 4px;

        font-size: 15px;
        line-height: 1.2;
        letter-spacing: 0.01em;

        &--bordered {
            @include rmin(1024) {
                margin-bottom: 16px;

                border-bottom: 1px solid $darkestGrey; } } }

    &__header-hidden {
        text-align: center;

        @include r(1023) {
            display: none; } }

    &__main {
        display: flex;
        align-items: center;
        min-width: 84px;

        font-size: 14px;
        line-height: 1.29;
        color: $lightestGrey; }

    &__img {
        position: absolute;
        left: 0;
        width: 51px;
        height: 51px;
        margin-right: 16px;

        object-fit: cover;
        object-position: bottom;

        @include r(1023) {
            top: 5px; } }

    &__link {
        color: $lightestGrey;
        line-height: 1.1; }

    &__main--header {
        min-width: 132px; }

    &__date {
        min-width: 86px;

        font-size: 15px;
        line-height: 1.2;
        letter-spacing: -0.02em; }

    &__data {
        min-width: 45px;

        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.02em;

        &--center {
            text-align: center;

            @include r(1023) {
                text-align: start; } }

        &--last {
            margin-left: auto;
            text-align: end; } }

    &__total {
        display: flex;
        justify-content: space-between;

        color: $grey;
        text-transform: initial; }

    &__text {
        font-size: 15px;
        line-height: 1.4; }

    &__sum {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.02em; } }

//actions styles

.active {
    color: $white; }

.form--invalid {
    border-bottom: 1px solid $red;

    &:placeholder-shown {
        border-bottom: 1px solid $red; } }

.form__label--invalid {
    color: $red;
    text-transform: initial; }
