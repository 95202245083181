.not-found {
    height: calc(100vh - 130px);

    @include r(1023) {
        height: calc(100vh - 93px); }

    .container {
        height: 100%; }

    &__inner {
        border-top: 1px solid $darkGrey;

        height: 100%;

        padding: 12px 0 15px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, fit-content(100%));
        grid-auto-rows: 1fr;
        grid-template-areas: "not-found x8" "title title" "x infinity"; }

    &__label {
        @include useFont("Arial Narrow", sans-serif);
        color: darkGrey;
        font-size: 13px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.26px;

        margin-bottom: 32px;

        &--404 {
            grid-area: not-found; }

        &--X8 {
            grid-area: x8;
            justify-self: flex-end; } }

    &__title {
        grid-area: title;

        color: $madWhite;
        font-family: 'Neue Haas Grotesk Display', sans-serif;
        font-weight: 600;
        @include css-lock(54, 88, 380, 1535);
        @include css-lock(44, 72, 380, 1535, line-height);
        @include css-lock(-2.649, -4.358, 380, 1535, letter-spacing);
        text-transform: lowercase;

        span {
            display: block; } }

    &__logo {
        align-self: flex-end;

        color: $white;

        svg {
            fill: currentColor; }

        &--x {
            grid-area: x; }

        &--infinity {
            grid-area: infinity;
            justify-self: flex-end; } } }
