.search {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;

    width: 100%;
    height: 110vh;
    padding-top: 71px;

    background: $black;
    overflow: auto;

    transform: translateY(-120vh);

    @include r(767) {
        max-height: initial;
        padding-bottom: 25px; }

    &__form {
        width: 100%;
        overflow: hidden;

        input {
            padding: 12px 0 0;

            text-transform: uppercase;
            outline: none;

            &::placeholder {
                font-size: 13px;
                font-weight: 400;
                line-height: 0.92;
                letter-spacing: -0.02em; } } }

    &__results {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 84px 18px;

        margin-top: 15px;

        @include r(767) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 36px 15px; } }

    &__item {
        display: table;
        user-select: none;
        height: 50vh; }

    &__image {
        position: relative;

        display: block;
        margin-bottom: 18px;
        background-color: #ECECED;

        @include r(767) {
            margin-bottom: 16px; }

        img {
            display: block;
            width: 100%;
            object-fit: cover; } }

    &__info {
        display: flex;
        justify-content: space-between; } }

.search-line {
    overflow: hidden;
    &__element {
        height: 1px;
        background-color: $darkGrey;
        border: none; } }
