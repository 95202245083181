.swiper-button-next,
.swiper-button-prev {
	right: 24%;
	padding: 5px;
	mix-blend-mode: difference;
	@include r(1023) {
		display: none; }
	&:after {
		content: "0020";
		width: 30px;
		background: url("../img/right.svg") no-repeat;
		color: $white;
		font-size: 10px; } }
.swiper-button-prev {
	right: initial;
	left: 24%;
	&:after {
		content: "0020";
		width: 30px;
		background: url("../img/left.svg") no-repeat; } }
.swiper-wrapper {
	align-items: center; }

.swiper-slide {
	cursor: inherit; }

.swiper-pagination {
	padding-right: 15px;
	text-align: right;

	font-family: inherit;
	font-size: 13px;
	line-height: 0.92;
	letter-spacing: -0.02em; }

.swiper-slide-title {
	display: none;
	@include r(1023) {
		margin-top: 14px;

		font-weight: 400;
		font-size: 13px;
		line-height: 0.92;
		letter-spacing: -0.02em;

		color: $white;
		text-transform: initial;
		text-align: center;
		mix-blend-mode: difference; } }
