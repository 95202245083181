.slider {
	padding: 15px 0;
	background-color: #eaeaeb; }

.main_slider {
	background: $background;
	user-select: none;
	@include r(1023) {
		height: 560px;
		background-color: $background; }
	.swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		@include r(767) {
			padding: 40px 0; }
		img {
			display: block;
			width: 100%;
			max-height: 750px;
			object-fit: contain;

			will-change: transform;
			@include r(1023) {
				height: 222px;
				object-fit: cover; } } } }
