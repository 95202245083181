.promo {
	overflow: hidden;
	// height: 100vh
	// aspect-ratio: 3/2
	position: relative;
	// border: 10px solid red
	height: 0;
	padding-bottom: 56.25%;
	background: #000;
	@include r(768) {
		height: 100vh;
		aspect-ratio: auto; } }

.promo__img {
	display: block;
	width: 100vw;
	position: absolute;
	top: -15%;
	bottom: -0%;
	left: 0;
	right: 0;
	@include r(768) {
		position: static; }

	img {
		width: 100%;
		height: 100%;
		object-fit: cover; }
	// object-fit: cover
	// img
	// 	max-width: 100%
	// 	position: absolute
	// 	top: 50%
	// 	left: 50%
	// 	transform: translate(-50%, -50%)

	@include r(768) {
		height: 100vh;
		width: 100%;
		object-fit: cover; } }
