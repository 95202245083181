.page-overlap {
    position: relative;

    // height: 100vh

    @include r(1023) {
        height: auto; } }

.card {
    @include bgs; }

.js-scrolling-sections-container {
    margin-top: -130px;
    padding-top: 130px;
    // border: 2px solid green
    position: relative;
    @include r(1023) {
        margin-top: 0;
        padding-top: 0; } }
.scrolling-section {
    // border:  5px solid red
    // overflow: hidden
    // border: 2px solid red
    position: absolute;
    top: 0;
    width: 100%;
    // top: 100vh
    -webkit-backface-visibility: hidden;
    @include r(1023) {
        position: static;
        top: auto; }
    &:first-child {
        top: 130px;
 }        // top: 0
    &_nomargin {
        top: 0 !important;
 } }        // margin-top: -130px
