.product-list {
	// position: absolute

	height: 100vh;
	width: 100%;
	z-index: 10;
	@include r(1023) {
		position: relative;
		height: 100%; }

	&__slider {
		cursor: inherit;
		height: max-content; }

	&__filter {
		display: flex;
		width: 100%;
		height: max-content;
		margin-top: auto;
		margin-bottom: 24px;
		justify-content: space-between;
		align-items: center;

		@include r(1023) {
			.link {
				display: none; } }
		a {
			align-self: flex-end;
			margin-bottom: 10px;
			line-height: 1.3; } }

	& .filter {
		width: calc(25% - 12px);

		@include r(767) {
			width: calc(50% - 8px); } } }
