.product {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	padding: 65px 0 200px;

	@include r(1439) {
		padding: 75px 0 200px; }

	@include r(767) {
		padding: 95px 0 0;
		.link {
			display: none; } }

	&__item {
		display: table;
		user-select: none;
		height: auto;

		&.isHidden {
			display: none; } }

	&__image {
		position: relative;

		display: block;
		margin-bottom: 18px;
		background-color: #ECECED;
		overflow: hidden;

		will-change: transform;

		&:hover {
			.product__img {
				opacity: 0;
				transition: opacity 100ms cubic-bezier(0.66, 0, 0.34, 1); } }

		@include r(767) {
			margin-bottom: 16px; }

		&--double {
			img {
				width: 100%;
				object-fit: cover;
				aspect-ratio: 1/1; } }

		img {
			top: -10%;

			display: block;
			width: 100%;
			object-fit: cover; } }

	&__hover {
		transform: translateY(-10%); }

	&__img {
		position: absolute;

		opacity: 1;
		will-change: transform;
		transition: opacity 100ms cubic-bezier(0.66, 0, 0.34, 1); }

	&__info {
		display: flex;
		justify-content: space-between; } }

.categories {
	.product__img {
		transform: scale(1.2); } }
