.add_btn {
	width: 100%;
	display: block;

	font-weight: 400;
	font-size: 13px;
	line-height: 12px;

	padding: 20px 12px;

	letter-spacing: -0.02em;

	color: $black;
	background: transparent;

	border: 1px solid $black;

	transition: all .2s linear;
	&:hover {
		background: $black;
		color: $white; } }

