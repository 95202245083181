.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 150;

	width: 100%;
	padding: 10px 0;

	mix-blend-mode: difference;
	&.active {
		background: $black;
		mix-blend-mode: unset;
		transition: background 1s cubic-bezier(0.12, 0, 0.39, 0); } }

.mob_logo {
	margin-left: 10px;
	@include r(600) {
		display: none; } }
.header_inner {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: center; }

.header_btn {
	padding: 15px 0;
	font-family: "Arial Narrow";
	font-weight: 400;
	font-size: 13px;
	line-height: 0.92;
	letter-spacing: -0.02em;

	color: $white;
	mix-blend-mode: difference;

	background: transparent;
	outline: none;
	cursor: pointer;
	border: 0; }

.header_search {
	margin-right: 12px;

	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer; }
.header_info {
	&.none {
		display: none; } }
.nav_toggle {
	position: absolute;
	left: 50%;
	z-index: 100;

	width: 36px;
	height: 26px;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	border: 0;
	opacity: 1;
	pointer-events: all;

	transform: translateX(-50%);

	&.none {
		opacity: 0;
		pointer-events: none;
		transition: opacity 1s cubic-bezier(0.12, 0, 0.39, 0); }
	&:before,
	&:after {
		position: absolute;
		content: "";
		left: 10px;

		width: 16px;
		height: 1px;

		background: $burgerGrey;

		transition: transform .8s cubic-bezier(0.860, 0.000, 0.070, 1.000), top .8s cubic-bezier(0.860, 0.000, 0.070, 1.000), bottom .8s cubic-bezier(0.860, 0.000, 0.070, 1.000);
		transform-origin: center;
		transform: rotate(0); }
	&:before {
		top: 10px; }
	&:after {
		bottom: 10px; }
	&.active {
		&:before {
			transform: rotate(135deg);
			top: 12px; }
		&:after {
			transform: rotate(45deg);
			bottom: 13px; } } }
