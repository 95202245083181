.line {
    overflow: hidden;
    padding: 12px 0;

    &__element {
        height: 1px;
        margin: 0;

        background-color: $darkGrey;
        border: none; } }
