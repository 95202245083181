.nav {
    font-weight: 500;
    font-size: 89px;
    line-height: 1;
    letter-spacing: -0.049em;
    color: $darkestGrey;
    text-transform: lowercase;

    mix-blend-mode: difference;

    transition: color 400ms linear;

    @include r(767) {
        font-size: 42px;
        line-height: 1.2; }

    &__link {
        font-family: 'Neue Haas Grotesk Display', sans-serif;
        list-style: none;
        overflow: hidden;

        a {
            display: block; }

        &:hover {
            color: $madWhite;
            transition: color 400ms linear; } } }
