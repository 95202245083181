body {
	background: $black;
	text-transform: uppercase;
	@include useFont("Arial Narrow", sans-serif); }

html {
	scroll-behavior: auto;
	overscroll-behavior: none;
	width: 100%;
	min-height: 100%;
	scrollbar-width: none !important; }

html body {
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		width: 0 !important;
		height: 0 !important; } }

html, body {
  min-height: 100%;
  height: auto; }

.container,
.mob_container {
	width: 100%;
	padding: 0 15px; }
.main {
	padding-top: 130px;
	background: $black;
	@include r(1023) {
		padding-top: 93px; } }
.shadow {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -2;

	width: 100%;
	height: 100vh;

	background: $black;
	opacity: 0;
	transition: opacity .3s cubic-bezier(0.12, 0, 0.39, 0), z-index .3s cubic-bezier(0.12, 0, 0.39, 0);
	&.active {
		z-index: 110;
		opacity: 0.8;
		pointer-events: none; } }
.loader {
	background-color: $black;
	padding: 16px;
	display: flex;
	flex-direction: column;
	transform-origin: top;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;

	@include useFont('Neue Haas Grotesk Display', sans-serif);
	font-weight: 500;
	&__overlay {
		background-color: #101010;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform-origin: center top; }
	&__row {
		@include css-lock(54, 88, 375, 1535, height);
		overflow: hidden;
		will-change: transform;
		&:nth-child(2) {
			transform: translateY(-15px);
			@include r(870) {
				transform: translateY(-10px); } }
		&:nth-child(3) {
			transform: translateY(-30px);
			@include r(870) {
				transform: translateY(-20px); } }
		&:nth-child(4) {
			transform: translateY(-45px);
			@include r(870) {
				transform: translateY(-30px); } } }
	&__text {
		@include useFont('Neue Haas Grotesk Display', sans-serif);
		font-weight: 500;
		@include css-lock(54, 88, 380, 1535);
		@include css-lock(44, 72, 380, 1535, line-height);
		white-space: nowrap;
		letter-spacing: -0.049em;
		color: #D4D5D5;
		transform: translateY(150px);
		opacity: 0;
		text-transform: lowercase;
		@include r(380) {
			@include css-lock(42, 54, 320, 380);
			letter-spacing: -0.049em; }
		.primary-word,
		.secondary-word {
			display: inline-block;
			@include useFont('Neue Haas Grotesk Display', sans-serif);
			font-weight: 500; }
		.primary-word {
			transform: translateX(100px); }
		.secondary-word {
			transform: translateX(200px); } }
	&__logo {
		margin-top: auto;
		display: flex;
		align-items: center;
		position: relative;

		.icon-logo-x,
		.icon-logo-infinity {
			fill: #D4D5D5;
			font-size: 16px; }

		.icon-logo-ver-2-x,
		.icon-logo-ver-2-infinity {
			fill: #fff;
			font-size: 35px; }

		.icon-logo-infinity, {
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(320px); }

		.icon-logo-ver-2-infinity {
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(200px); } } }

.transition {
	display: flex;
	position: absolute;

	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	margin: 0;
	pointer-events: none;
	z-index: -1;
	li {
		transform: scaleY(1);
		background: $black;
		width: 20%; } }
.page_links {
	margin-bottom: 40px;
	a {
		color: #ffffff;
		font-weight: bold; } }
@media (max-width: 700px) {
	.mob_container {
		padding: 0; } }

@keyframes hideLoaderAnimation {
	0% {
		transform: translateY(0); }
	100% {
		transform: translateY(-100%); } }

.sail {
	inset: 0;
	z-index: 100;
	position: fixed;
	height: 100vh;
	display: none;

	&__shape {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #101010;
		transform-origin: center top; } }

.js-sticky-visible {
	width: 100%; }

.js-sticky-section, .js-sticky-parallax img, .js-promo-parallax img {
	will-change: transform; }
