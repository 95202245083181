.sort {
    display: flex;

    &__container {
        position: relative;

        width: 100%;
        overflow: hidden;

        @include rmin(1024) {
            width: 50%;
            margin-right: 16px; }

        &::before, &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0px;

            width: 100%;
            height: 1px;
            background-color: $darkGrey; }

        &::before {
            z-index: 10;
            background-color: $hoverline;
            transform: translateX(-100%);
            transition: transform 500ms cubic-bezier(0.66, 0, 0.34, 1); }

        &:hover {
            &::before {
                transform: translateX(0); } } }

    &__label {
        position: absolute;
        right: 0;
        bottom: 20%;

        display: block;
        width: 55px;

        color: $grey;
        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;

        background-image: url("../img/filter.svg");
        background-repeat: no-repeat;
        background-position: right;
        pointer-events: none; }

    &__select {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 13px;

        color: $white;
        font-family: inherit;
        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;

        background: transparent;
        border: 0;

        appearance: none;
        -webkit-appearance: none; }

    &__option {
        background-color: $black;

        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em; } }

.sort--bordered {
    @include rmin(1024) {
        border-bottom: 1px solid $darkGrey;
        width: 49%; } }
