.footer {
	padding: 200px 0 30px;
	position: relative;
	background-color: $black;
	@include r(1023) {
		padding: 96px 0 30px; } }
.footer_inner {
	display: flex;
	justify-content: space-between; }
.footer_nav {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 45px 10px;
	flex: 1;
	.footer_title {
		margin-bottom: 20px; }
	ul {
		li {
			a {
				display: table;

				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: -0.02em;
				text-transform: uppercase;
				color: $grey;
				&:hover {
					color: $darkGrey; } } } } }

.footer_info {
	display: flex;
	padding-top: 9px;
	padding-bottom: 68px;
	border-top: 1px solid $darkGrey; }
.footer_list {
	width: 100%;
	overflow: hidden; }
.footer_list-inner {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include rmin(1023) {
		&:first-child {
			.footer_list-model {
				padding-top: 0; } } } }
.footer_list-model {
	position: relative;
	display: flex;
	justify-content: space-between;

	width: 66.2%;
	padding: 10px 0 10.5px;

	color: $white;
	font-size: 14px;
	line-height: .69;

	border-bottom: 1px solid $darkGrey;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 1.5px;
		background-color: $hoverline;
		transform: translateX(-100%);
		transition: transform 500ms cubic-bezier(0.66, 0, 0.34, 1); }
	&:hover,
	&.active {
		&::after {
			transform: translateX(0);
			transition: transform 500ms cubic-bezier(0.66, 0, 0.34, 1); } }
	p {
		font-size: 14px;
		line-height: .69; }
	span {
		color: $grey;
		opacity: 0.7; }
	&.active {
		span {
			color: $white; } }
	&.active + .footer_content-colors {
		display: flex;
		span {
			opacity: 1;
			&:first-child {
				transition: opacity 0.3s linear 0.1s; }
			&:nth-child(2) {
				transition: opacity 0.3s linear 0.2s; }
			&:nth-child(3) {
				transition: opacity 0.3s linear 0.3s; }
			&:last-child {
				transition: opacity 0.3s linear 0.4s; } } } }

.footer_content {
	width: 370px;
	flex-shrink: 0;
	position: relative; }
.footer_content-photos {
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
	overflow: hidden; }
.footer_photos-wrapper {
	width: 77px;
	height: 77px;
	overflow: hidden;
	&:not(:last-child) {
		margin-right: 4px; }

	@include r(390) {
		width: 67px;
		height: 67px; } }
.footer_photos {
	display: block;
	margin: 0;
	width: 100%;

	scale: 1.1;
	transition: scale 1s ease; }
.footer_content-colors {
	display: flex;
	align-items: center;
	width: 31.65%;
	height: 16px;
	span {
		display: table;

		width: 6px;
		height: 6px;

		border-radius: 50%;
		opacity: 0;
		transition: opacity 0.4s linear;
		&.blue {
			background-color: $blue; }
		&.orange {
			background-color: $orange; }
		&.brown {
			background-color: $green; }
		&.red {
			background-color: $red; }
		&:first-child {
			margin-right: 6.8%; }
		&:nth-child(2) {
			margin-right: 2%; }
		&:nth-child(3) {
			margin: 0 auto; }
		&:last-child {
			margin-right: 0; } } }

.footer_content-inner {
	position: absolute;
	display: flex;

	flex-direction: column;
	justify-content: flex-end;

	height: 100%;
	width: 100%;
	opacity: 0;
	pointer-events: none;

	transition: opacity .1s linear;
	&.active {
		opacity: 1;
		pointer-events: all; }
	&.active .footer_photos {
		scale: 1;
		transition: scale 1s ease; } }
.footer_model-name {
	text-align: right;

	color: $white;
	line-height: 0.71;
	opacity: 0.7; }

.newsletter {
	width: 24%;
	padding-left: 8px;
	@include r(1023) {
		padding-left: 0; }
	&__form-inner {
		position: relative;
		margin-bottom: 17px;
		button {
			position: absolute;
			right: 0;
			bottom: 0;

			display: flex;
			align-self: end;
			padding: 11px 0 19px;

			font-weight: 400;
			font-size: 14px;
			line-height: 12px;
			letter-spacing: -0.02em;

			color: $white;
			background: transparent; } }
	&__label {
		display: inline-block;
		height: 14px; }
	&__input {
		padding-bottom: 13px;

		font-family: inherit;
		border-bottom: 1px solid $darkGrey; }
	&__text {
		font-weight: 400;
		font-size: 15px;
		line-height: 12px;
		color: $darkGrey;
		text-transform: initial; }

	form {
		margin-bottom: 20px; } }

.copyright {
	position: absolute;
	right: 10px;
	bottom: 34px;

	display: flex;
	align-items: flex-start;
	gap: 2px;

	color: $darkGrey;
	font-size: 14px;
	line-height: 1.1;
	letter-spacing: -0.02em;

	&_text {
		margin-left: 10px;
		font-size: 15px;
		line-height: 1.1;
		letter-spacing: -0.02em;

		text-transform: initial; }

	&_link {
		display: inline-block;
		color: $darkGrey;
		font-size: 15px;
		line-height: 1.1;
		letter-spacing: -0.02em;
		text-transform: initial; } }

@media (max-width: 991px) {
	.footer_info {
		display: flex;
		flex-direction: column-reverse; }
	.footer_list {
		width: 100%;
		border-top: 1px solid $darkGrey; }
	.footer_list-inner {
		position: relative;
		border-bottom: 1px solid $darkGrey; }
	.footer_list-model {
		border-bottom: 0;
		width: 100%; }
	.footer_content-colors {
		position: absolute;
		right: 25%;
		bottom: 25%;

		width: 50%; }
	.footer_content {
		width: 100%;
		height: 	140px;
		margin: 0 0 20px auto; }
	.footer_model-name {
		margin-bottom: 25px;
		text-align: start; } }
@media (max-width: 850px) {
	.footer_inner {
		display: block; }
	.footer_nav {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 45px 15px;
		flex: 1; }
	.newsletter {
		width: 70%;
		margin: 70px 0; }
	.copyright {
		position: initial; } }

@media (max-width: 450px) {
	.newsletter {
		width: 100%; } }
