.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-logo-infinity {
  width: 4.56em;
  height: 1em;
  fill: initial;
}
.icon-logo-ver-2-infinity {
  width: 0.3em;
  height: 1em;
  fill: initial;
}
.icon-logo-ver-2-x {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-logo-x {
  width: 2.41em;
  height: 1em;
  fill: initial;
}
