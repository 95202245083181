.js-sticky-text {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	cursor: default;
	pointer-events: none;
	opacity: 0;

	color: $white;
	font-size: 13px;
	font-weight: 400;
	line-height: 0.92;
	letter-spacing: -0.02em;
	mix-blend-mode: difference; }
