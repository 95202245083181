.card {
	// position: absolute
	z-index: 20;

	width: 100%;
	will-change: transform;
	@include r(1023) {
		position: relative; }

	&__wrapper {
		position: relative;

		display: flex;
		height: 100vh;
		overflow: hidden;

		@include r(1023) {
			position: initial;
			flex-wrap: wrap;
			height: auto; }

		@include rmin(1024) { // to fix a bug with a blinking line
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 16px;
				height: 100%;

				background-color: $black;
				content: ''; }

			&::after {
				position: absolute;
				top: 0;
				right: 0;
				width: 16px;
				height: 100%;

				background-color: $black;
				content: ''; } } }

	&__img {
		overflow: hidden;
		width: 50%;
		background-color: $black;

		@include r(1023) {
			height: 100%;
			width: 100%;
			transform: translateY(0); }

		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			@include r(1023) {
				height: 560px; } } }

	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0 15px 16px;
		width: 50%;

		background-color: #fff;

		@include r(1023) {
			width: 100%;
			min-height: 560px;
			padding: 7px 16px;
			order: 2; } }

	&__title {
		display: flex;
		justify-content: space-between;

		h2 {
			@include useFont('Neue Haas Grotesk Display', sans-serif);
			font-weight: 500; } }

	&__bottom {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@include r(1023) {
			margin-bottom: 9px; } }

	&__center {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;

		img {
			max-width: 100%; } }

	&__img-wrapper {
		display: flex; } }
