.details {
    &__title {
        margin-bottom: 2px;

        color: $grey;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.02em; }

    &__name {
        font-size: 15px;
        line-height: 1.67;
        letter-spacing: 0.01em;
        text-transform: capitalize; }

    &__email {
        margin-bottom: 40px;

        font-size: 15px;
        line-height: 1.67;
        letter-spacing: 0.01em;
        text-transform: lowercase; } }
