.categories {
    &__view {
        padding-top: 24px;
        padding-bottom: 8px;
        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;

        color: $grey;
        text-decoration: none;

        &::before {
            content: url(../img/view4.svg);
            margin-right: 12px;

            @include r(767) {
                content: url(../img/view2.svg); } }

        &::after {
            content: '4';
            @include r(767) {
                content: '2'; } } }

    &__inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 80px 15px;

        @include r(767) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 40px 15px; } } }
