.article {
	padding-top: 125px;

	&__title {
		display: block;
		max-width: 1097px;
		width: fit-content;
		margin: 0 auto 24px;

		@include useFont('Neue Haas Grotesk Display', sans-serif);
		color: $white;
		font-weight: 500;
		font-size: 86px;
		line-height: 0.88;
		letter-spacing: -0.06em;
		text-align: center;

		@include r(1023) {
			max-width: 548px;
			font-size: 43px; } }

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@include r(1023) {
			display: block;
			margin-bottom: 40px; } }

	&__container {

		max-width: 744px;
		margin: 0 auto;
		padding: 0 16px;

		@include r(1023) {
			width: 100%;
			padding: 0; } }

	&__img-wrapper {
		overflow: hidden; }

	&__info.center {
		margin: 0 auto; }

	&__wrapper {
		width: 100%;

		&.center {
			margin: 0 auto; } }

	&__img {
		max-height: 731px;

		overflow: hidden;
		transform: translateY(-5%);

		@include r(767) {
			display: flex;
			justify-content: center;

			img {
				margin-top: 30px; } }

		img {
			object-fit: cover; } }

	&__img-bottom {
		display: flex;
		justify-content: center;
		margin: 40px auto 0;
		overflow: hidden;

		&--wrapper {
			max-height: 730px;
			width: 100%; }

		img {
			width: 100%; } }

	&__aside {
		position: sticky;
		display: flex;
		justify-content: space-between;
		top: 75px;
		width: 100%;

		padding-top: 12px;
		border-top: 1px solid $darkGrey;

		@include r(1023) {
			position: initial;
			margin-bottom: 15px;

			&:last-child {
				margin: 15px 0 0;

				@include r(767) {
					margin: 0; } } }

		p {
			font-weight: 400;
			font-size: 13px;
			line-height: 12px;

			letter-spacing: -0.02em;
			text-transform: initial;
			color: $grey; } }

	&__aside--right {
		justify-content: end;

		@include r(767) {
			justify-content: start; } }

	&__share {
		font-weight: 400;
		font-size: 13px;
		line-height: 1.1;
		letter-spacing: -0.02em;
		color: $grey;
		text-transform: initial;

		background-color: transparent; }

	&__text {
		margin: 100px 0 140px;

		@include useFont('Neue Haas Grotesk Display', sans-serif);
		font-style: normal;
		font-weight: 500;
		font-size: 88.943px;
		line-height: 0.81;
		letter-spacing: -0.049em;
		color: $madWhite;
		text-transform: initial;

		@include r(1023) {
			font-size: 54px;
			margin: 120px 0; } } }

.info {
	border-bottom: 1px solid $darkestGrey;

	@include r(1023) {
		width: 100%; }

	&__title {
		margin-bottom: 0;

		font-size: 14px;
		line-height: 1.5;
		letter-spacing: -0.02em; }

	&__inner {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		gap: 16px;

		@include r(767) {
			display: block; } }

	&__page {
		position: relative;
		bottom: 24px;

		display: flex;
		align-items: flex-end;
		width: fit-content;
		margin-left: auto;

		color: $grey;
		font-size: 14px;
		line-height: 0.71;
		letter-spacing: -0.02em;

		@include r(1023) {
			margin-left: 0;
			margin-top: 28px; } }

	&__text {
		width: 48%;

		@include r(767) {
			width: 100%; }

		p {
			font-weight: 400;
			font-size: 15px;
			line-height: 1.47;
			letter-spacing: 0.01em;
			text-transform: initial; }

		h4 {
			margin-bottom: 0; } } }
