.about {
    // position: relative

    // display: flex
    // flex-direction: column
    // height: 100vh
    // width: 100%
    // margin-top: -129px
    // padding: 0 15px

    // user-select: none

    @include r(1023) {
        height: auto; }

    @include r(767) {
        padding: 0; }
    &__cover {
        padding-bottom: 15px; }
    &__section {
        position: relative;
        display: flex;
        height: 100%;
        will-change: transform;

        @include rmin(1024) {
            height: 100vh;
            // position: absolute
            top: 0;
            left: 0;
            width: 100%; } }

    &__wrapper {
        display: flex;
        min-height: 100vh;
        width: 100%;

        padding: 0 15px;

        overflow: hidden;

        @include r(1023) {
            flex-direction: column;
            padding: 0; }

        &--top {
            justify-content: flex-end;
            align-items: flex-end;
            height: fit-content;
            padding: 70px 15px 0;

            @include r(1023) {
                padding-top: 131px;
                padding-top: 0 !important;
                justify-content: flex-start; } }

        &--article {
            display: block;
            padding: 165px 0 170px;
            background-color: $black;

            @include r(1023) {
                padding: 132px 0 148px; } }

        &--bottom {
            z-index: 10; } }

    &__attributes {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;

        border-top: 1px solid $darkGrey;

        p {
            color: $grey;
            font-size: 13px;
            line-height: 0.92;
            letter-spacing: -0.02em;
            text-transform: initial; }

        &--sticky {
            position: sticky;
            left: 16px;
            width: 24.2%;

            @include r(1023) {
                width: calc(100vw - 36px);
                margin-bottom: 20px; } }

        &--right {
            right: 16px;
            justify-content: end;

            @include r(767) {
                display: none; } } }

    &__subtitle {
        // margin: 16px 0 36px

        @include useFont('Neue Haas Grotesk Display', sans-serif);
        font-weight: 500;
        font-size: calc( (100vw - 375px)/(1535 - 375) * (89 - 54) + 54px);
        line-height: 0.81;
        letter-spacing: -0.049em;

        color: $madWhite;
        mix-blend-mode: difference;
        text-transform: initial;

        @include r(1023) {
            margin: 31px 0 80px; } }

    &__image {
        position: relative;

        width: 100%;
        height: 100%;
        overflow: hidden;

        @include rmin(1024) {
            width: 50%; }

        @include r(1023) {
            height: 100vh; }

        img {
            display: block;
            height: 100%;
            width: 100%;
            margin: 0;
            object-fit: cover; } }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 12px 16px 19px;

        background-color: $white;

        @include rmin(1024) {
            width: 50%; }

        @include r(1023) {
            height: 100vh; }

        p {
            color: $black; } }

    &__product {
        @include useFont('Neue Haas Grotesk Display', sans-serif);
        font-weight: 500;
        font-size: 35px;
        line-height: 1.03;
        letter-spacing: -0.06em;

        span {
            margin-left: 12px;
            @include useFont('Neue Haas Grotesk Display', sans-serif);
            font-weight: 500; } }

    &__link {
        padding-bottom: 1px;
        font-size: 13px;
        line-height: 1.1;
        letter-spacing: -0.02em; }

    &__description {
        align-self: start;

        font-size: 14px;
        line-height: 1.21;
        letter-spacing: -0.02em; }

    &__article-cover {
        margin-bottom: 20px;

        @include r(767) {
            margin-bottom: 0; } }

    &__article-title {
        display: block;
        width: fit-content;
        margin: 0 auto 20px;
        padding-right: 13px;
        text-align: center;

        @include useFont('Neue Haas Grotesk Display', sans-serif);
        font-weight: 500;
        color: $white;
        font-size: calc( (100vw - 375px)/(1535 - 375) * (86 - 43) + 43px);
        line-height: 0.88;
        letter-spacing: -0.06em; }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include r(1023) {
            flex-direction: column;
            align-items: center; }

        a {
            overflow: hidden;

            @include r(767) {
                padding: 0px 15px;
                height: 100%;
                width: 100%; } } }

    &__pic {
        overflow: hidden;
        background-color: #0B1612;

        @include r(767) {
            transform: translateY(0); }

        img {
            width: 100%; } }

    &__share {
        display: inline-flex;
        width: fit-content;

        color: $grey;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        text-transform: initial;
        background-color: transparent;

        @include r(767) {
            margin-bottom: 40px;
            margin-left: 2px; } }

    &__text {
        display: flex;
        align-items: flex-end;
        width: 50%;
        padding: 16px;

        background-color: $white;

        @include r(1023) {
            width: 100%;
            height: 100vh; }

        p {
            width: 90%;

            @include useFont('SuisseWorks', sans-serif);
            color: $black;
            font-size: 11px;

            line-height: 1.3;
            letter-spacing: 0.01em;

            @include rmin(768) {
                max-width: 70%; }

            @include rmin(1024) {
                max-width: 45%; } } } }

.isFiltered {
    img {
        filter: grayscale(100%); } }
