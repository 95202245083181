.blog {
    height: 100%;

    @include useFont("Arial Narrow", sans-serif);

    @include r(1024) {
        margin-bottom: -96px; }

    &__wrapper {
        margin-top: 24px; }

    &__articles {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; } }

.post {
    position: relative;
    height: 100vh;

    color: $grey;

    &:not(:first-child) {
        @include r(1023) {
            border-top: 1px solid $darkGrey;
            padding-top: 24px; } }

    &:not(:first-child) .post__attributes {
        @include rmin(1024) {
            border-top: 1px solid $darkGrey;
            padding-top: 24px; } }

    @include r(1023) {
        height: auto; }

    &__wrapper {
        position: relative;

        display: flex;
        justify-content: flex-end;

        transform-origin: top;
        will-change: transform, scale;

        transform: translate3d(0, 0, 0);

        @include r(1023) {
            flex-direction: column; } }

    &__img {
        display: block;
        width: 100%;

        background-size: cover;
        background-position-x: center;
        background-repeat: no-repeat;

        @include rmin(1024) {
            position: absolute;
            top: 0;
            left: 0;

            min-height: 100vh;
            max-width: 50%;
            height: 100vh; }

        @include r(1023) {
            min-height: 100vw; } }

    &__info {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        padding: 120px 16px;

        @include rmin(1024) {
            padding: 0 110px;
            max-width: 50%;
            height: 100vh; }

        @include r(1023) {
            min-height: 345px; } }

    &__attributes {
        position: absolute;
        bottom: 319px;
        right: 0;

        display: flex;
        justify-content: space-between;
        width: 100%;

        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;
        text-transform: initial;

        @include rmin(1024) {
            top: 0;
            right: 16px;

            width: calc(50% - 32px); } }

    &__attr--link {
        text-transform: uppercase;
        color: inherit; }

    &__title {
        @include useFont('Neue Haas Grotesk Display', sans-serif);
        font-weight: 500;
        color: white;
        font-size: 43px;
        line-height: 0.84;

        text-align: center;
        letter-spacing: -0.06em;

        @include r(1023) {
            font-size: 31px; } } }

.hidden-img {
    position: fixed;
    top: 0px;

    z-index: -10;
    display: block;
    height: 50vh;
    visibility: hidden; }
