.hero {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow: hidden;
	padding-bottom: 20px;

	height: 776px;

	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: calc(100% - 20px);
		top: 0;
		left: 0;
		background-image: url(../img/short.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: -20px; } }

.video {
	position: relative;
	z-index: 2;

	display: flex;
	object-fit: cover;
	text-align: center;
	height: 100%;

	opacity: 1;
	transition: all 100ms linear;

	@include rmin(1440) {
		width: 100%; }
	@include r(768) {
		width: auto; }
	&-long {
		position: absolute;
		z-index: 1; } }
