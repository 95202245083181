.single {
	margin-top: -130px;
	@include css-lock(96, 160, 375, 1535, margin-bottom);

	background: $white;

	position: relative;

	&::after,
	&::before {
		content: '';
		position: absolute;
		width: 15px;
		top: 0;
		bottom: 0;
		background-color: $black;

		@include r(700) {
			display: none; } }

	&::after {
		left: 0; }

	&::before {
		right: 0; }

	.product-list {
		position: relative; }

	&_sticky {
		position: relative;

		display: flex;
		@include r(1023) {
			flex-direction: column; } }
	&_inner {
		position: sticky;
		top: 0;

		align-self: flex-start;
		width: 43%;
		padding: 60px 15px 20px 15px;

		@include rmin(1024) {
			display: flex;
			flex-direction: column;
			height: 100vh;
			overflow-y: auto; }

		@include r(1023) {
			padding: 15px 15px 110px;
			position: relative; } }
	&_info {
		@include rmin(1024) {
			margin-top: auto; } }
	&_close {
		position: fixed;
		top: 24px;
		right: 15px;
		z-index: 151;

		display: none;

		font-weight: 400;
		font-size: 13px;
		line-height: 12px;
		text-align: right;
		letter-spacing: -0.02em;
		text-transform: uppercase;

		mix-blend-mode: difference;
		background: transparent;

		@include r(768) {
			top: 15px; }

		&.active {
			display: block; } }
	&_name {
		display: flex;
		justify-content: space-between;
		padding-right: 3px;
		margin-bottom: 20px;

		@include useFont('Neue Haas Grotesk Display', sans-serif);
		font-weight: 500;
		white-space: nowrap; }
	&_collection {
		display: flex;
		align-items: center;
		@include css-lock(40, 60, 375, 1920, height);
		@include useFont('Neue Haas Grotesk Display', sans-serif);
		font-weight: 500; }
	&_model {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 205px;
		width: 30%;
		padding-right: 3px;
		overflow: hidden;
		@include css-lock(40, 66, 375, 1920, height); }
	&_model-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		min-width: inherit;
		flex-shrink: 0;
		padding-top: 2px;
		gap: 70px;
		@include r(767) {
			gap: 30px; }
		@include r(560) {
			gap: 20px; } }

	&_info-text {
		margin: 15px 0;

		@include useFont('SuisseWorks', sans-serif);
		font-weight: 400;
		font-size: 11px;
		line-height: 15px;

		letter-spacing: 0.01em;
		text-transform: uppercase; }
	&_top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap; }
	&_price {
		display: none;
		&.active {
			display: block; } }
	&_top-info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 4px 8px;
		img {
			@include r(390) {
				margin-left: auto; } }
		p {
			text-transform: initial;
			color: $darkGrey;
			opacity: .7; }
		.link {
			padding-bottom: 1px;
			text-transform: initial; } }
	&_btn {
		background-color: transparent; } }

.thumbs {
	display: flex;
	max-width: 360px;
	margin: 15px 0 50px;
	@include r(1023) {
		margin-bottom: 96px; }
	img {
		cursor: pointer;
		display: block;
		width: 24%;
		border-bottom: 1px solid transparent;
		&.active {
			border-bottom-color: $black; }
		&:not(:last-child) {
			margin-right: 5px; } } }

.zoom {
	position: fixed;
	inset: 0;
	z-index: -1;

	width: 100%;
	text-align: center;

	background: $background;

	opacity: 0;
	transition: all .2s linear;
	transition: opacity 1000ms cubic-bezier(0.175, 0.885, 0.320, 1.275);

	display: flex;
	align-items: center;
	&.active {
		height: 100vh;
		z-index: 90;
		opacity: 1;
		transition: opacity 1000ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
		overflow: hidden; }
	&__img {
		object-fit: contain;
		width: 100%; } }

.product_nav {
	position: fixed;
	top: 15px;
	transform: translateY(50%);
	left: calc(58% + 15px);
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 20;
	color: $darkWhite;
	mix-blend-mode: difference;
	@include r(1023) {
		display: none; }

	.product_nav-text {
		font-weight: 400;
		font-size: 13px;
		line-height: 12px;
		letter-spacing: -0.02em; } }

.gallery {
	width: 60%;
	&__container {}

	.swiper-pagination {
		display: none;
		@include r(1023) {
			display: block;
			text-align: end; } }
	.swiper-slide {
		cursor: pointer;
		@include r(1023) {
			display: -webkit-box;
			overflow: auto; }
		&__zoom-box {
			background-position: 50% 50%;
			position: relative;
			overflow: hidden;
			&:hover {
				img {
					opacity: 0; } }
			@include r(1023) {
				background-image: none !important;
				&:hover {
					img {
						opacity: 1; } } } }
		img {
			display: block;
			width: 100%;
			transition: opacity 0.5s; } }

	&_images {
		width: 50%;
		padding-top: 200px; }
	&_slides {
		overflow: hidden; } }

.toggler {
	display: none;
	color: $black;
	&.active {
		display: block; } }
.toggle_title {
	position: relative;

	font-weight: 400;
	font-size: 13px;
	line-height: 10px;

	letter-spacing: -0.02em;

	cursor: pointer;
	padding: 10px 15px 10px 0;

	border-top: 1px solid $black;
	&:before {
		position: absolute;
		content: "+";
		right: 0;
		top: 10px; } }
.toggle_info {
	display: none;
	padding-bottom: 15px; }

.color {
	padding: 10px 0;
	display: flex;
	justify-content: space-between;

	border-top: 1px solid $black;
	&.blue {
		.color_title {
			&:before {
				background: $blue; } } }
	&.orange {
		.color_title {
			&:before {
				background: $orange; } } }
	&.green {
		.color_title {
			&:before {
				background: $green; } } }
	&.pink {
		.color_title {
			&:before {
				background: $pink; } } }

	&_title {
		font-weight: 400;
		font-size: 13px;
		line-height: 10px;

		letter-spacing: -0.02em;

		position: relative;
		display: table;
		&:before {
			position: absolute;
			content: "";

			width: 6px;
			height: 6px;

			top: 50%;
			right: -15px;
			transform: translateY(-50%);

			border-radius: 50%; } } }

.colors {
	display: flex;
	align-items: center;
	.dot_color {
		display: table;
		width: 6px;
		height: 6px;

		margin-left: 12px;

		border-radius: 50%;
		&.blue {
			background: $blue; }
		&.orange {
			background: $orange; }
		&.green {
			background: $green; }
		&.pink {
			background: $pink; } } }

@media (max-width: 1023px) {
	.single {
		display: block; }
	.gallery,
	.single_inner {
		width: 100%; }
	.single_top {
		display: block; }
	.single_price {
		margin: 15px 0; }
	.gallery {
		height: fit-content !important; } }

.swiper-slide {
	&__wrapper {
		overflow: hidden;
		transform: translateY(-70px);
		@include r(1023) {
			transform: translateY(0); } }
	&__img {
		width: 100%;
		will-change: transform; } }
