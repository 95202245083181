.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 98;

    width: 100%;
    padding: 111px 0 25px;

    overflow-y: scroll;
    background: $black;

    transform: translateY(-200%);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.320, 1.275);

    @include r(767) {
        padding-bottom: 25px; }

    &::-webkit-scrollbar {
        width: 0; }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;

        color: $darkGrey;
        text-transform: uppercase;
        overflow: hidden; } }
