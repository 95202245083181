.topic {
    &__select {
        width: 100%;
        padding-bottom: 11px;

        color: $white;
        font-family: inherit;
        font-size: 15px;
        line-height: 1.5;
        letter-spacing: 0.01em;

        background: transparent;
        background-image: url("../img/arrow.svg");
        background-repeat: no-repeat;
        background-position: right;
        border: 0;
        border-bottom: 1px solid $darkGrey;

        appearance: none;
        -webkit-appearance: none; }

    &__option {
        background: $black;
        padding: 0;

        font-family: inherit;
        font-size: 15px;
        line-height: 1.4;
        letter-spacing: 0.01em; } }

.textarea {
    border-bottom: 1px solid $darkGrey;

    &__message {
        display: block;
        width: 100%;

        color: $white;
        font-family: inherit;
        font-size: 15px;
        line-height: 1.5;

        background: $black;
        border: 0;
        resize: none; } }
