.links {
    font-weight: 500;
    font-size: 89px;
    line-height: 0.81;
    letter-spacing: -0.049em;
    color: $darkestGrey;
    text-transform: lowercase;

    mix-blend-mode: difference;

    transition: color 400ms linear;

    @include r(767) {
        font-size: 48px; }

    &__date {
        display: flex;
        justify-content: space-between;
        padding: 12px 0 32px;

        font-size: 13px;
        line-height: 0.92;
        letter-spacing: -0.02em;

        color: $darkGrey;
        border-top: 1px solid $darkGrey;
        text-transform: uppercase; }

    &__link {
        font-family: 'Neue Haas Grotesk Display', sans-serif;

        &:hover {
            color: $madWhite;

            transition: color 400ms linear; } } }
