.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;

    width: 100%;

    background: $black;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s cubic-bezier(0.12, 0, 0.39, 0) 0.2s;

    &.active {
        z-index: 10;
        opacity: 0.8;
        pointer-events: fill;
        transition: opacity 0.5s cubic-bezier(0.12, 0, 0.39, 0) 0.2s; } }
